<template>
	<div class="more full-height flex-column ">
		<div class="mt-50">
			<ul>
				<li class="banner bg_banner1 justify-center items-center">
					<div class="text-center">
						<img :src="require('@/assets/image/logo_horizontal_white.svg')" class="" style="width:250px"/>
						<img :src="require('@/assets/image/logo_horizontal_white2.png')" class="mt-20" style="width:232px"/>
					</div>
				</li>
			</ul>
		</div>
		<div class="mt-50 pa-20">
			<ul
				class=""
			>
				<li
					v-for="(menu, m_index) in items"
					:key="'menu_' + m_index"
					@click="toMenu(menu)"
					class="inline-block width-25 text-center mb-30"
				>
					<button  class="color-white ico_collection" :class="menu.icon">{{ menu.name }}</button>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'more'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'more'
				}
				, items: [
					{ name: this.$language.launchpad.title_launchpad, to: { name: 'SaleNftList', params: {}}, icon: 'icon-launchpad'}
					, { name: 'NFT', to: { name: 'nft', params: {}}, icon: 'icon-nft'}
					, { name: 'WALLET', to: { name: 'wallet', params: {}}, icon: 'icon-wallet'}
					, { name: 'STAKING', to: { name: 'staking', params: {}}, icon: 'icon-staking'}
					, { name: 'MY 불렛', to: { name: 'PointList', params: {}}, icon: 'icon-bullet'}
					, { name: 'NFT 구매내역', to: { name: 'NftHistory', params: {}}, icon: 'icon-list'}
					, { name: '구독내역', to: { name: 'MypageSubscribeList', params: {}}, icon: 'icon-fav'}
				]
			}
		}
		, methods: {
			toMenu: function(item){
				if(this.user.member_number){
					this.$bus.$emit('to', item.to)
				}else{
					this.$bus.$emit('onLogin', '', item.to)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
		}
	}
</script>

<style>

	.more .ico_collection { padding: 5rem 0 0; text-align: center; background: no-repeat top center / 4.2rem 4.2rem; background-image: url('../../assets/image/ico_collection.png');}
	.more .ico_collection button {  font-size: 12px; font-weight: 400 !important;}

	.banner { width: 100%; min-height: 150px;}
	.bg_banner1 { background: url('../../assets/image/more_banner1.jpg') no-repeat 100%; background-size: cover}

	.icon-launchpad { background-image: url('../../assets/image/icon-launchpad.png') !important;}
	.icon-nft { background-image: url('../../assets/image/icon-nft.png') !important; padding-left: 1rem !important; padding-right: 1rem !important;}
	.icon-wallet { background-image: url('../../assets/image/icon-wallet.png') !important;}
	.icon-staking { background-image: url('../../assets/image/icon-staking.png') !important;}
	.icon-bullet { background-image: url('../../assets/image/icon-bullet.png') !important;}
	.icon-list { background-image: url('../../assets/image/icon-list.png') !important;}
	.icon-fav { background-image: url('../../assets/image/icon-fav.png') !important;}

</style>